import React from 'react';

import Story from './components/story';

export default function Perkbox() {
  const props = {
    name: 'Perkbox',
    title:
      'Why Perkbox chose Fidel API to reduce friction for employees at the point of purchase, and provide businesses with a clear ROI',
    seoTitle: 'How Perkbox reduced customer friction with Fidel API',
    subtitle: 'With Laura Camfield, Product Manager at Perkbox',
    heroImgAlt:
      'Two people sitting in a sofa in an office environment and smiling',
    keyFigures: {
      industry: 'Human Resources',
      hq: 'London, UK',
      funding: '$ 12.2 M',
      employees: '250+',
    },
    companyElement: (
      <p>
        Perkbox helps employees live better. In life and at work. Using Perkbox,
        businesses attract, engage and retain their best talent with all the
        things that make for a winning company culture — perks, employee
        benefits and great rewards. In fact Perkbox is so good, all Fidel
        employees are members!
      </p>
    ),
    challengeTitle:
      'Seizing new opportunities for a better employee experience',
    challengeElement: (
      <div>
        <p>
          With over half a million employees and over 8,000 employers on their
          platform, Perkbox wanted to increase engagement by making the
          redemption experience smoother for employees.
        </p>
        <p>
          When it comes to redeeming perks, the user journey is not always
          simple and seamless. Employees log into their Perkbox account, select
          an offer and then, depending on the type of perk, either purchase a
          gift card or download a discount code before going to the partners
          website or shop to spend. These steps mean that some employees are{' '}
          dropping off during their redemption journey or not engaging with the
          platform at all.
        </p>
        <p>
          For Perkbox customers, being able to see their return on investment
          was a crucial reason for choosing Perkbox. But with online discounts,
          Perkbox isn’t able to track whether a purchase was made and how much
          was saved.
        </p>
        <p>
          In addition, Perkbox saw an opportunity for growth and differentiation
          by adding local, small and medium retailers to its catalogue of perks.
          But, many of these businesses didn’t have the technological capability
          to submit offers via the Perkbox platform to its more than a million
          employees.
        </p>
      </div>
    ),
    highlight:
      '“Without Fidel API’s technology, we would have no way to include local, small and medium businesses on our platform.”',
    highlightImgAlt: 'Screenshot of Perkbox’s app on a smartphone',
    quoteAuthor: {
      name: 'Laura Camfield',
      description: 'Product Manager at Perkbox',
      avatar: 'perkbox_representative',
    },
    solutionTitle:
      'Going big: Supporting scale while reducing employee drop-off',
    solutionElement: (
      <div>
        <p>
          Using Fidel API’s Select Transactions API, Perkbox launched a new way
          to save — “PerksGO”.
        </p>
        <p>
          Now all employees need to do to benefit from the Perks platform is
          link their payment card once and they can save when they shop. It’s
          free, secure and takes seconds. They can shop as normal, their
          earnings will automatically arrive in their Perkbox wallet. They can
          then use those savings to pay for other perks like gift cards on the
          platform.
        </p>
        <p>
          This is expected to go a long way to reducing Perkbox customer churn
          as the redemption experience is seamless for employees, and employers
          can see how much their employees have saved each year with PerksGO.
        </p>
        <p>
          For retailers, they can easily see how many & how often customers are
          redeeming perks. Using Fidel API’s business enrolment dashboard,
          Perkbox has already onboarded more than 350 local small and medium
          businesses, all without the need for any additional hardware or
          multiple integrations.
        </p>
        <p>
          The first phase of this card-linking programme launched in London to
          700 customers with over 18,000 employees. Perkbox will continue its
          rollout city by city across the UK as they build their partner
          network, and eventually across other countries. Perkbox will also add
          in-app notifications in April so employees can see in real-time the
          rewards they have earned.
        </p>
      </div>
    ),
  };

  return <Story {...props} />;
}
